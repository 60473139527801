import { BaseEntity } from './BaseEntity';

export enum SubscriptionRenew {
  YES = 1,
  NO = 0
}

export interface SubscriptionChannelStatistic {
  name: string
  value: number
}

export interface Subscription extends BaseEntity {
  username: string
  channelName: string
  plan: string
  email: string
  startedAt: string
  endedAt: string
  renew: SubscriptionRenew
}

export type PartialSubscription = Partial<Subscription>;
