
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  toRefs,
  watch
} from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';
import { getSubscriptionsChannelStatistic } from '@/services/api';

export default defineComponent({
  props: {
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    planId: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    const { planId } = toRefs(props);
    const { mounted, chart, beforeDestroy, activated, deactivated } = resize();
    const formatterChannelName = (data: any) => {
      return data.map(item => {
        if (item.name === null) {
          return {
            name: 'organic',
            value: item.value
          };
        }
        return item;
      });
    };
    const renderChart = async() => {
      if (planId.value) {
        const { data } = await getSubscriptionsChannelStatistic({
          query: { planId: `${planId.value}` }
        });
        const pieChart = init(
        document.getElementById('subscriptionPieChart') as HTMLDivElement,
        'macarons'
        );
        pieChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
            top: 'bottom'
          },
          series: [
            {
              type: 'pie',
              // fake info
              // data: [
              //   { name: 'lyon', value: 1 },
              //   { name: 'michael', value: 10 },
              //   { name: 'joshua', value: 5 },
              //   { name: 'kobe', value: 8 },
              //   { name: 'howard', value: 0 },
              //   { name: 'louis', value: 11 },
              //   { name: 'bill', value: 25 }
              // ],
              data: formatterChannelName(data),
              animationEasing: 'cubicInOut',
              animationDuration: 2000,
              // TODO: 暂时关闭 label
              label: {
                show: false,
                position: 'center'
              }
            }
          ]
        } as EChartsOption);
        chart.value = pieChart;
      }
    };

    watch(planId, () => {
      renderChart();
    });

    onMounted(() => {
      mounted();
      nextTick(() => {
        renderChart();
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {

    };
  }
});
