import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fa8ab2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pie-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PieChartSubscription = _resolveComponent("PieChartSubscription")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_main = _resolveComponent("el-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PieChartSubscription, { planId: _ctx.planId }, null, 8, ["planId"])
    ]),
    _createVNode(_component_el_main, null, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          data: _ctx.data?.data,
          border: "",
          fit: "",
          "highlight-current-row": "",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "ID"
            }, {
              default: _withCtx(({row}) => [
                _createTextVNode(_toDisplayString(row.id), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "USER"
            }, {
              default: _withCtx(({row}) => [
                _createVNode(_component_router_link, {
                  to: `/users/${row.user_id}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_link, {
                      type: "primary",
                      underline: false
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row.user.username), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "CHANNEL"
            }, {
              default: _withCtx(({row}) => [
                _createElementVNode("span", null, _toDisplayString(row.channelName || 'organic'), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "STARTED AT"
            }, {
              default: _withCtx(({row}) => [
                _createElementVNode("span", null, _toDisplayString(row.startedAt), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "ENDED AT"
            }, {
              default: _withCtx(({row}) => [
                _createElementVNode("span", null, _toDisplayString(row.endedAt), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "CREATED AT"
            }, {
              default: _withCtx(({row}) => [
                _createElementVNode("span", null, _toDisplayString(row.createdAt), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])), [
          [_directive_loading, _ctx.isLoading || _ctx.isFetching]
        ]),
        _createVNode(_component_el_pagination, {
          currentPage: _ctx.page,
          "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
          "page-size": _ctx.data?.meta?.perPage,
          layout: "prev, pager, next, jumper",
          total: _ctx.data?.meta?.total
        }, null, 8, ["currentPage", "page-size", "total"])
      ]),
      _: 1
    })
  ], 64))
}