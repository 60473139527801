
import { defineComponent, ref } from 'vue';
import { useSubscriptions } from '@/composables/api';
import { SubscriptionRenew } from '@/interfaces/Subscription';
import { useRoute } from 'vue-router';
import PieChartSubscription from './components/PieChartSubscription.vue';

export default defineComponent({
  components: {
    PieChartSubscription
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const planId = useRoute().query.planId as string;
    const { data, isLoading, isFetching } = useSubscriptions({ page, keyword, planId });

    return {
      page,
      data,
      planId,
      isLoading,
      isFetching,
      SubscriptionRenew
    };
  }
});
